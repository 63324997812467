const handleCreatorsTableHeading = ({ hasAccessToActions }) => {
  const creatorsTableHeading = [
    {
      id: 0,
      title: "Profile",
      display: true,
    },
    {
      id: 1,
      title: "Name",
      display: true,
    },
    {
      id: 2,
      title: "Phone",
      display: true,
    },
    {
      id: 3,
      title: "Email ID",
      display: true,
    },
    {
      id: 4,
      title: "Onboarding",
      display: true,
    },

    {
      id: 5,
      title: "No. Videos",
      display: true,
    },
    {
      id: 6,
      title: "Manager",
      display: false,
    },
    {
      id: 7,
      title: "Actions",
      display: hasAccessToActions,
    },
  ];
  return creatorsTableHeading;
};

const detailsDefaultState = {
  name: "",
  mobileNumber: "",
};

const creatorsTabs = [
  {
    id: "ALL",
    key: "SHOWS",
    value: "all",
    index: 0,
    show: true,
  },
  {
    id: "ACTIVE",
    key: "ACTIVE",
    value: "active",
    index: 1,
    show: true,
  },
  {
    id: "INACTIVE",
    key: "INACTIVE",
    value: "in active",
    index: 2,
    show: true,
  },
];

export { detailsDefaultState, creatorsTabs, handleCreatorsTableHeading };
