const getDateString = (date) => {
  if (!date) return "NA";
  const dateString = new Date(date);

  return dateString.toDateString().substring(4, 15);
};

const getTimeString = (date) => {
  if (!date) return "NA";
  const dateString = new Date(date);

  return dateString.toTimeString().substring(0, 9);
};

const getFileExtension = (filename) => {
  return filename ? filename?.split(".")?.pop() : "";
};

const validateImage = (filename) => {
  var allowedFiles = ["png", "jpeg", "jpg"];
  const extension = getFileExtension(filename)?.toLowerCase();
  if (allowedFiles.indexOf(extension) > -1) {
    return true;
  } else {
    return false;
  }
};

const filterKeywordToBackendKeywords = (filterName) => {
  switch (filterName) {
    case "categoryFilter":
      return "categories";
    case "statusFilter":
      return "seriesStatus";
    case "reviewStatus":
      return "seriesStatus";
    case "communityFilter":
      return "communities";
    case "contentManagerFilter":
      return "contentManager";
    case "experimentFilter":
      return "experiment";
    default:
      return "seriesStatus";
  }
};

const validateVideo = (filename) => {
  var allowedFiles = ["mp4"];
  const extension = getFileExtension(filename)?.toLowerCase();
  if (allowedFiles.indexOf(extension) > -1) {
    return true;
  } else {
    return false;
  }
};

const getReviewStatus = (status) => {
  if (status.toLowerCase() === "pending") {
    return <div className="review-pending">Pending</div>;
  } else if (status.toLowerCase() === "approved") {
    return <div className="review-approved">Approved</div>;
  } else if (status.toLowerCase() === "rejected") {
    return <div className="review-rejected">Rejected</div>;
  } else if (status.toLowerCase() === "hidden") {
    return <div className="review-pending">Hidden</div>;
  } else if (status.toLowerCase() === "deleted") {
    return <div className="review-rejected">Deleted</div>;
  }
};
const months = [
  "Jan",
  "Feb",
  "Mar",
  "Apr",
  "May",
  "Jun",
  "Jul",
  "Aug",
  "Sept",
  "Oct",
  "Nov",
  "Dec",
];

const handleFormatDate = ({ dateInString, showTime = true }) => {
  if (!dateInString) {
    return "-";
  }

  const dateused = new Date(Date.parse(dateInString));
  const month = months[dateused.getMonth()];
  const date = dateused.getDate();
  const year = dateused.getFullYear();
  const hours = dateused.getHours();
  let mins = dateused.getMinutes();
  // Pad minutes with leading zero if needed
  mins = mins < 10 ? `0${mins}` : mins;
  return `${date} ${month} ${year} ${showTime ? `at ${hours}:${mins}` : ""}  `;
};

const startTime = { hour: 18, minute: 0, second: 0, millisecond: 0 };

const schedularTimeList = (os) => {
  const scheduleTime = [];
  const numDates = 3;
  const currentDate = new Date();
  const dynamicDate = new Date(currentDate.getTime());
  dynamicDate.setHours(
    startTime.hour,
    startTime.minute,
    startTime.second,
    startTime.millisecond
  );
  let currentTimeDate = new Date().getTime();
  const fixedScheduleTimeDate = dynamicDate.getTime();
  const options =
    os === 0
      ? { year: "numeric", month: "2-digit", day: "2-digit" }
      : { year: "numeric", month: "2-digit", day: "2-digit" };
  for (let i = 0; i < numDates; i++) {
    if (fixedScheduleTimeDate - currentTimeDate > 0) {
      const date = new Date(currentDate.getTime() + i * 24 * 60 * 60 * 1000); // Add 24 hours for each iteration
      date.setHours(
        startTime.hour,
        startTime.minute,
        startTime.second,
        startTime.millisecond
      );
      scheduleTime.push({
        title:
          i == 0
            ? "Today Evening"
            : i == 1
            ? "Tomorrow Evening"
            : `${getDay(date)} Evening`,
        time: "06: 00 PM",
        value: date.toLocaleDateString("en-US", options),
      });
    } else {
      const date = new Date(
        currentDate.getTime() + (i + 1) * 24 * 60 * 60 * 1000
      ); // Add 24 hours for each iteration
      date.setHours(
        startTime.hour,
        startTime.minute,
        startTime.second,
        startTime.millisecond
      );
      scheduleTime.push({
        title:
          i == 0
            ? "Tomorrow Evening"
            : i == 1
            ? `${getDay(date)} Evening`
            : `${getDay(date)} Evening`,
        time: "06: 00 PM",
        value: date.toLocaleDateString("en-US", options),
      });
    }
  }
  return scheduleTime;
};

const getDay = (day, next) => {
  let weekDays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];
  let nextDay = new Date(day).getDay();
  return weekDays[nextDay];
};

const getDuration = (durationInSeconds) => {
  const min = Math.floor(durationInSeconds / 60);
  const seconds = durationInSeconds % 60;
  return `${min}m ${seconds}s`;
};

// const errorResponseCodeToMessageMapping = (response_code) => {
//   if (response_code === 200 || response_code === 201) {
//     return "Contact the support team for help !";
//   } else if (response_code === 400) {
//     return response_code + " Invalid action !";
//   }
//   return "You dont have permissions for this access !";
// };

// Function to convert url to file
const handleUrlToFile = (url, filename, mimeType) => {
  return fetch(url)
    .then(function (res) {
      return res.arrayBuffer();
    })
    .then(function (buf) {
      return new File([buf], filename, { type: mimeType });
    })
    .catch((error) => {
      console.error("Error while converting url to file:", error);
    });
};

// function which will add default profile, if user has no profile.
const handleDefaultProfile = ({ userName }) => {
  if (!userName) {
    return "https://i.pinimg.com/originals/0d/64/98/0d64989794b1a4c9d89bff571d3d5842.jpg";
  }
  const url = `https://ui-avatars.com/api/?background=random&name=${userName}`;
  return url;
};

// function to check the environment
const handleCheckEnvironment = () => {
  if (typeof window !== "undefined") {
    const { href } = window.location;
    if (href.toLowerCase().includes("cms.seekhoapp")) {
      return true;
    }
    return false;
  }
};

const handleFormatDateTimeAgo = ({ date }) => {
  if (date) {
    const CreatedDate = new Date(date);
    const today = new Date();
    const timeDifference = today.getTime() - CreatedDate.getTime();
    const seconds = Math.floor(timeDifference / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days >= 365) {
      return days === 365
        ? `${Math.floor(days / 365)}y`
        : `${Math.floor(days / 365)}y`;
    }
    if (days >= 30) {
      return days === 30
        ? `${Math.floor(days / 30)}mo`
        : `${Math.floor(days / 30)}mo`;
    }
    if (days > 0) {
      return days === 1 ? `${days}d` : `${days}d`;
    }
    if (hours > 0) {
      return hours === 1 ? `${hours}h` : `${hours}h`;
    }
    if (minutes > 0) {
      return minutes === 1 ? `${minutes}min` : `${minutes}min`;
    }
    return seconds === 1 ? `${seconds}s` : `${seconds}s`;
  }
  return null;
};

const handleFormatNumberOfFollowers = ({ numberOf }) => {
  if (!numberOf) return 0;
  if (numberOf >= 1_000_000_000) {
    return `${(numberOf / 1_000_000_000).toFixed(1)}B`;
  }
  if (numberOf >= 1_000_000) {
    return `${(numberOf / 1_000_000).toFixed(1)}M`;
  }
  if (numberOf >= 1_000) {
    return `${(numberOf / 1_000).toFixed(1)}K`;
  }
  return numberOf?.toString() || 0;
};

const handleConvertUTCToIST = (utcDateString) => {
  const date = new Date(Date.parse(utcDateString));
  date.setHours(date.getHours() + 5);
  date.setMinutes(date.getMinutes() + 30);
  return handleFormatDate({ dateInString: date.toISOString() });
};

// common function which will append queryName and its value in the url
const handleAppendQueryToUrl = ({ history, queryName, queryValue }) => {
  const searchParams = new URLSearchParams(history?.location?.search);
  searchParams.set(queryName, queryValue);
  history.push(`${history.location.pathname}?${searchParams.toString()}`);
};

// Removes the query from the url
const handleDeleteQueryFromUrl = ({ history, queryNames = [] }) => {
  const searchParams = new URLSearchParams(history.location.search);
  queryNames.forEach((queryName) => {
    searchParams.delete(queryName);
  });
  history.push(`${history.location.pathname}?${searchParams.toString()}`);
};

// Appends the Filter Keys to the URL with a dynamic filter name
const handleAppendFiltersToUrl = ({
  history,
  filters = [],
  filterName = "filter",
}) => {
  // Ensure filters is an array
  const filtersArray = Array.isArray(filters) ? filters : [filters];

  const searchParams = new URLSearchParams(history.location.search);
  const existingFilters = searchParams.getAll(filterName);

  // Creating Sets for easy comparison
  const currentFiltersSet = new Set(filtersArray);
  const existingFiltersSet = new Set(existingFilters);

  // Determine which filters need to be added
  const filtersToAdd = filtersArray.filter(
    (filter) => !existingFiltersSet.has(filter)
  );

  // Determine which filters need to be removed
  const filtersToRemove = existingFilters.filter(
    (filter) => !currentFiltersSet.has(filter)
  );

  // Append new filters
  filtersToAdd.forEach((filter) => {
    searchParams.append(filterName, filter);
  });

  // Remove filters that are no longer selected
  filtersToRemove.forEach((filter) => {
    searchParams.delete(filterName, filter);
  });

  // Update the URL with the new search parameters
  history.push(`${history.location.pathname}?${searchParams.toString()}`);
};

// Removes the particular filter key from url
const handleRemoveParticularFilter = ({
  filterName = "filter",
  filterToRemove,
  history,
}) => {
  const searchParams = new URLSearchParams(history.location.search);
  const existingFilters = searchParams.getAll(filterName);
  const existingFiltersSet = new Set(existingFilters);
  if (existingFiltersSet.has(filterToRemove)) {
    searchParams.delete(filterName, filterToRemove);
  }
  history.push(`${history.location.pathname}?${searchParams.toString()}`);
};

// Function modifies every word's first letter to capital in a sentence
const handleModifyFirstLetterToCapital = ({ sentence }) => {
  const sentenceCased = sentence
    ?.split(" ")
    ?.map(
      (word) => word?.charAt(0)?.toUpperCase() + word?.slice(1)?.toLowerCase()
    )
    ?.join(" ");

  return sentenceCased;
};

// copy to clipboard function
const handleCopyTextToClipboard = async ({ clipboardText }) => {
  try {
    await navigator.clipboard.writeText(clipboardText);
  } catch (err) {
    console.error("Failed to copy to clipboard:", err);
  }
};

const handleFormatDateWithSlash = ({ dateString }) => {
  if (!dateString) return;
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = String(date.getFullYear()).slice(-2);
  return `${day}/${month}/${year}`;
};

const handleAllyProps = ({ index }) => {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
};

// Function to download this series thumbnail
const handleDownloadThumbnail = async ({ imageUrl, title }) => {
  const url =
    process.env.NODE_ENV === "development"
      ? "https://staging.seekhoapp.com"
      : "";
  const imageData = new FormData();
  imageData.append("imageUrl", imageUrl);
  try {
    const response = await fetch(url + "/image-data", {
      method: "POST",
      body: imageData,
    });
    const data = await response.json();
    const file = await handleUrlToFile(
      `data:image/jpeg;base64,${data.b64}`,
      "thumbnail.jpeg",
      "image/jpeg"
    );
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(file);
    downloadLink.download = `${title}jpeg`;
    downloadLink.click();
  } catch (error) {
    console.error("Error while image download:", error);
  }
};

export {
  getDateString,
  getReviewStatus,
  getTimeString,
  validateImage,
  validateVideo,
  getFileExtension,
  filterKeywordToBackendKeywords,
  handleFormatDate,
  getDuration,
  schedularTimeList,
  handleUrlToFile,
  handleDefaultProfile,
  handleCheckEnvironment,
  handleFormatDateTimeAgo,
  handleFormatNumberOfFollowers,
  handleConvertUTCToIST,
  handleAppendFiltersToUrl,
  handleRemoveParticularFilter,
  handleModifyFirstLetterToCapital,
  handleCopyTextToClipboard,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
  handleFormatDateWithSlash,
  handleAllyProps,
  handleDownloadThumbnail,
};
