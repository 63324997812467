import React from "react";
import { handleCreatorsTableHeading } from "../../utils/constants/Creators";
import { handleDefaultProfile } from "../../utils/utils";
import { Dropdown } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { useHistory } from "react-router-dom";
import useZustandStore from "../../zustandStore/useZustandStore";

const ListingTable = ({ data, handleApproveOrDisApprove }) => {
  const history = useHistory();
  const internalTeamAccessGlobalState = useZustandStore(
    (val) => val?.internalTeamAccessGlobalState
  );
  const hasAccessToActions = internalTeamAccessGlobalState;

  return (
    <div>
      <table className="w-full text-sm text-left rtl:text-right text-gray-500">
        <thead className="text-gray-700 border-b bg-gray-100">
          <tr>
            {handleCreatorsTableHeading({ hasAccessToActions }).map(
              (item) =>
                item?.display && (
                  <th
                    scope="col"
                    className="px-6 py-3 !text-[12px] font-medium bg-gray-100 whitespace-nowrap z-[999]"
                    key={item?.id}
                  >
                    {item?.display && item?.title}
                  </th>
                )
            )}
          </tr>
        </thead>
        <tbody>
          {data?.map((item) => {
            return (
              <tr
                className="!font-normal border-b last:border-none"
                key={item?.id}
              >
                <td className="px-6 py-4 whitespace-nowrap">
                  <img
                    src={
                      item?.avatar ||
                      handleDefaultProfile({ userName: item?.name })
                    }
                    alt={item.name}
                    className="!w-[40px] rounded-full"
                  />
                </td>
                <td
                  scope="row"
                  className="px-6 py-4 whitespace-nowrap text-black text-[14px] cursor-pointer"
                  onClick={() => history.push(`/creators/${item?.id}`)}
                >
                  {item?.name}
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.phone}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.email || "-"}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.signedup_on}
                </td>

                <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.n_videos}
                </td>

                {/* Will be used later */}
                {/* <td class="px-6 py-4 whitespace-nowrap text-gray-500 text-[14px]">
                  {item?.content_manager}
                </td> */}

                {hasAccessToActions && (
                  <td className="px-6 py-4 whitespace-nowrap">
                    <Dropdown className="w-100">
                      <Dropdown.Toggle className="dropdown-action-common">
                        <FontAwesomeIcon
                          className="rejection-logs ml-1"
                          icon={faEllipsisV}
                          size="1x"
                        />
                      </Dropdown.Toggle>
                      <Dropdown.Menu className="action-menu">
                        <Dropdown.Item
                          className="action-menu-item"
                          onClick={() => {
                            handleApproveOrDisApprove({
                              creatorId: item?.id,
                              isApprove: !item?.is_quality_approved,
                            });
                          }}
                        >
                          {item?.is_quality_approved ? "Disapprove" : "Approve"}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Dropdown>
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default ListingTable;
