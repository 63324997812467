import React, { useEffect, useState } from "react";
import { Box, LinearProgress, Pagination, Tab, Tabs } from "@mui/material";
import { selectConfigFilters } from "../../redux/config/config.selector";
import {
  GET_SHOWS_LIST_V1,
  GET_SHOW_ACTION_V1,
  errorState,
} from "../../utils/constants";
import { Button, Form, Modal, Row } from "react-bootstrap";
import { apiGateway } from "../../utils/config";
import { useSelector } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import { showListingTabs } from "../../utils/constants/Shows";
import {
  handleAllyProps,
  handleAppendFiltersToUrl,
  handleAppendQueryToUrl,
  handleDeleteQueryFromUrl,
  handleRemoveParticularFilter,
} from "../../utils/utils";
import AddIcon from "@mui/icons-material/Add";
import AlertComponent from "../Alert-Messages/alert-component.component";
import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import CreateShow from "../Create-Show/create-show.component";
import useZustandStore from "../../zustandStore/useZustandStore";
import QueryValueChip from "../Common/QueryValueChip";
import CustomSearch from "../Common/CustomSearch";
import ShowListTable from "./ShowListTable";
import NoFeedbackSvj from "../../assests/images/Feedback/NoFeedback";
import FilterButton from "../Common/FilterButton";
import CustomFilterDrawer from "../Common/CustomFilterDrawer";

export default function ShowsList() {
  const [pageCount, setPageCount] = useState(1);
  const [searchQuery, setSearchQuery] = useState("");
  const [searchQueryTag, setSearchQueryTag] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isDialogCreate, setIsDialogCreate] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [dialogStatus, setDialogStatus] = useState({ open: false });
  const [errorMsg, setErrorMsg] = useState(errorState);
  const [selectedShow, setSelectShow] = useState({});
  const [data, setData] = useState([]);
  const [selectedFilters, setSelectedFilters] = useState([]);
  const { globalRoles, internalTeamAccessGlobalState } = useZustandStore();
  const axiosPrivate = useAxiosPrivate();
  const configFilters = useSelector(selectConfigFilters);
  const history = useHistory();
  const location = useLocation();
  const searchParams = new URLSearchParams(location?.search);
  const searchParamPage = Number(searchParams.get("page")) || 1;
  const [pageNumber, setPageNumber] = useState(searchParamPage);
  const searchParamTabState =
    Number(searchParams?.get("state")?.split("-")[1]) || 0;
  const searchParamSearchQuery = searchParams?.get("query") || "";
  const searchParamCategoryFilter = searchParams?.getAll("category") || [];
  const isCreator = globalRoles?.creator;
  const isEditor = globalRoles?.editor;
  const isViewer = globalRoles?.viewer;
  const hasAccessToActions = internalTeamAccessGlobalState;
  const hasAccessToCreate = internalTeamAccessGlobalState;
  const hasAccessToCreatorTableField =
    internalTeamAccessGlobalState || isViewer;

  // API function call to get Shows
  const handleGetAllShows = async ({
    currentActiveTab = null,
    passedId,
    query,
    pageArg,
  }) => {
    setIsLoading(true);
    try {
      let state = showListingTabs.filter(
        (d) => d.index === currentActiveTab
      )?.[0]?.value;

      // Filtering out the category with the passed id, used while removing the added filteredTags
      const filteredCategories = searchParamCategoryFilter.filter(
        (category) => category?.split("-")[1] !== passedId
      );

      // Prepare query parameters for categories
      const categoryParams = filteredCategories
        .map((item) => `category_ids=${Number(item?.split("-")?.[1])}`)
        .join("&");

      let url = `${apiGateway}${GET_SHOWS_LIST_V1}/?page=${
        pageArg || pageNumber
      }&state=${state}&q=${query}`;

      // Add category if they exist
      if (categoryParams) {
        url += `&${categoryParams}`;
      }
      const response = await axiosPrivate.get(url);
      const { data, status } = response;
      if (
        status !== 200 ||
        (data?.error_message && Object.keys(data?.error_message).length > 0)
      ) {
        setErrorMsg({
          type: "failed",
          error: data?.error_message ?? "Something went wrong",
        });
      } else {
        setData(data?.show_list);
        setPageCount(data?.n_pages);
        setIsLoading(false);
      }
    } catch (error) {
      setData([]);
      setErrorMsg({
        type: "error",
        error: error?.response?.data?.error_message || error?.message,
      });
      setIsLoading(false);
    }
  };

  // Tab Change
  const handleChangeTabs = (event, newValue) => {
    let state = showListingTabs.filter((d) => d.index === newValue)?.[0]?.value;
    handleAppendQueryToUrl({
      history,
      queryName: "state",
      queryValue: `${state}-${newValue}`,
    });
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
  };

  // Page change
  const handlePageChange = (e, value) => {
    setPageNumber(value);
    handleAppendQueryToUrl({ history, queryName: "page", queryValue: value });
  };

  const toggleStatus = (show_slug, status) => {
    let formData = new FormData();
    formData.append("show_state", status);
    let url = `${apiGateway}${GET_SHOW_ACTION_V1}/${show_slug}/update-state/`;
    setIsLoading(true);
    axiosPrivate
      .post(url, formData)
      .then(({ data, status }) => {
        if (status == false) {
          setErrorMsg({
            type: "failed",
            error: data?.error_message ?? "Something went wrong",
          });
        } else {
          setIsLoading(false);
          handleGetAllShows({
            currentActiveTab: searchParamTabState,
            pageArg: 1,
            query: searchParamSearchQuery,
          });
          setErrorMsg({ type: "success", error: "Show Status Changed!!!" });
        }
      })
      .catch(({ response }) => {
        if (response?.status == 404) {
          setErrorMsg({ type: "failed", error: "Url not found" });
        } else {
          setErrorMsg({
            type: "error",
            error: response?.data?.error_message || response?.data?.message,
          });
        }
        setIsLoading(false);
      });
    setSelectShow({});
    setDialogStatus({ open: false });
  };

  const toggleCreate = (e, show_created) => {
    if (isDialogCreate && show_created) {
      handleGetAllShows({
        currentActiveTab: searchParamTabState,
        pageArg: 1,
        query: searchParamSearchQuery,
      });
    }
    setIsDialogCreate((prev) => !prev);
  };

  // On hit enter
  const handleInputKey = (ele) => {
    if (ele.key === "Enter") {
      handleAppendQueryToUrl({
        history,
        queryName: "query",
        queryValue: searchQuery,
      });
      handleAppendQueryToUrl({ history, queryName: "page", queryValue: 1 });
      setSearchQueryTag(searchQuery);
    }
  };

  // On click of clear CTA in the input
  const handleClearInput = () => {
    setSearchQuery("");
    handleDeleteQueryFromUrl({ history, queryNames: ["query"] });
  };

  // On Selected filter change
  const handleSelectedFilter = (event) => {
    const { value, checked } = event.target;
    setSelectedFilters((prevSelectedFilters) => {
      if (checked) {
        // Add the selected filter
        return [...prevSelectedFilters, value];
      } else {
        // Remove the unselected filter
        return prevSelectedFilters.filter((filter) => filter !== value);
      }
    });
  };

  // Clicked on Apply filter
  const handleApplyFilters = () => {
    handleAppendFiltersToUrl({
      filterName: "category",
      filters: selectedFilters,
      history,
    });
    setOpenFilterDrawer(false);
  };

  // Clicked on Remove filter
  const handleRemoveFilter = ({ filterName, filterToRemove }) => {
    handleRemoveParticularFilter({ filterName, filterToRemove, history });
  };

  // Toggle Show to move to live / move to draft
  const handleToggleShowLiveOrDraft = ({ item }) => {
    setSelectShow(item);
    setDialogStatus({
      open: true,
      type: item?.status === "draft" ? "live" : "draft",
      msg:
        item?.status == "draft"
          ? "Make this show live"
          : "Move this show to draft",
    });
  };

  // Delete show Modal
  const handleDeleteShow = ({ item }) => {
    setSelectShow(item);
    setDialogStatus({
      open: true,
      type: "deleted",
      msg: "Delete this show",
    });
  };

  // sets search query in state on refresh
  useEffect(() => {
    setSearchQuery(searchParamSearchQuery);
  }, [searchParamSearchQuery]);

  // API call on every dependency change
  useEffect(() => {
    handleGetAllShows({
      currentActiveTab: searchParamTabState,
      query: searchQuery || searchParamSearchQuery,
      pageArg: searchParamPage,
    });
  }, [
    searchParamSearchQuery,
    searchParamTabState,
    searchParamPage,
    JSON.stringify(searchParamCategoryFilter),
  ]);

  return (
    <div>
      {hasAccessToCreate && (
        <div
          className="cta-button-create"
          onClick={(e) => toggleCreate(e, false)}
        >
          <AddIcon fontSize="large" className="icon-plus-cta" />
          <span>Create Show</span>
        </div>
      )}

      {/* Search & Filter */}
      <div className="flex gap-x-4">
        <div className="w-[70%] lg:!w-[50%]">
          <CustomSearch
            searchQuery={searchQuery}
            placeHolder="Search show name or Id"
            handleInputKey={handleInputKey}
            setSearchQuery={setSearchQuery}
            handleClearInput={handleClearInput}
          />
        </div>

        <FilterButton
          onClick={() => {
            setOpenFilterDrawer((prev) => !prev);
            setSelectedFilters(searchParamCategoryFilter);
          }}
        />
      </div>

      <h6 className="!text-[20px] mt-3 font-medium">Shows</h6>

      {/* Search / filter display chips */}
      <div className="flex gap-x-2 flex-wrap">
        {searchParamCategoryFilter?.map((item) => {
          return (
            <>
              <div key={item}>
                <QueryValueChip
                  label={item?.split("-")?.[0]}
                  onDelete={() => {
                    handleRemoveFilter({
                      filterName: "category",
                      filterToRemove: item,
                    });
                  }}
                />
              </div>
            </>
          );
        })}

        {searchQueryTag || searchParamSearchQuery ? (
          <QueryValueChip
            sName="my-2 p-1 !bg-[#b1aaed] !text-white"
            label={searchQueryTag || searchParamSearchQuery}
            onDelete={() => {
              setSearchQuery("");
              setSearchQueryTag("");
              handleDeleteQueryFromUrl({
                history,
                queryNames: ["query"],
              });
            }}
          />
        ) : null}
      </div>

      <div id="HideScroll" className="mt-2">
        <div>
          <div className="card-panel creator-table">
            <Box>
              <Tabs
                value={searchParamTabState}
                onChange={handleChangeTabs}
                aria-label="basic tabs example"
                sx={{
                  minHeight: 35,
                  height: 35,
                }}
              >
                {showListingTabs?.map((data, index) => (
                  <Tab
                    label={data?.id}
                    {...handleAllyProps({ index })}
                    key={index}
                    sx={{
                      minHeight: 35,
                      height: 35,
                    }}
                  />
                ))}
              </Tabs>
            </Box>

            {isLoading && <LinearProgress color="inherit" />}
            {/* Show listing with Pagination & No data state */}
            <div
              id="ThinGrayScrollBar"
              className="!relative !overflow-x-auto h-[calc(100vh-217px)] rounded-md !bg-white pb-2 border-t"
            >
              {!isLoading && data?.length === 0 ? (
                <div className="h-full flex justify-center items-center">
                  <NoFeedbackSvj
                    displayText="No shows to display"
                    displayTextClass="mt-2 text-gray-400 text-[14px] text-center"
                    className="w-[250px] mx-auto flex justify-center items-center"
                  />
                </div>
              ) : (
                <div>
                  <ShowListTable
                    data={data}
                    isEditor={isEditor}
                    hasAccessToActions={hasAccessToActions}
                    setErrorMsg={setErrorMsg}
                    handleDeleteShow={handleDeleteShow}
                    handleToggleShowLiveOrDraft={handleToggleShowLiveOrDraft}
                    hasAccessToCreatorTableField={hasAccessToCreatorTableField}
                  />

                  {pageCount > 1 && (
                    <div className="w-fit mx-auto pb-2">
                      <Pagination
                        count={pageCount}
                        page={searchParamPage || pageNumber}
                        onChange={(e, val) => handlePageChange(e, val)}
                        shape="rounded"
                      />
                    </div>
                  )}
                </div>
              )}
            </div>
          </div>
        </div>
      </div>

      {/*  Filter Drawer */}
      {openFilterDrawer && (
        <CustomFilterDrawer
          open={openFilterDrawer}
          handleClose={() => setOpenFilterDrawer(false)}
          handleApplyFilters={handleApplyFilters}
          handleClearFilters={() => {
            handleDeleteQueryFromUrl({
              history,
              queryNames: ["category"],
            });
            setOpenFilterDrawer(false);
          }}
        >
          <div>
            <h5 className="filter-header px-2">Categories</h5>
            <Form.Group
              className="filters-list-series custom-drawer-list-series px-3"
              as={Row}
            >
              {configFilters.categories?.map((item, index) => {
                const filterValue = `${item.title}-${item.id}`;
                return (
                  <Form.Check
                    key={item.id + `${index}`}
                    type="checkbox"
                    name="categoryFilter"
                    onChange={handleSelectedFilter}
                    value={filterValue}
                    label={item.title}
                    checked={selectedFilters.includes(filterValue)}
                    className="form-col col-md-6 form-checkbox-align"
                  />
                );
              })}
            </Form.Group>
          </div>
        </CustomFilterDrawer>
      )}

      {/* Confirmation Modal To Make show live / draft / delete */}
      <Modal show={dialogStatus?.open} centered>
        <Modal.Header
          closeButton
          onHide={() => {
            setDialogStatus({ open: false });
            setSelectShow({});
          }}
        >
          {selectedShow?.title}
        </Modal.Header>
        <Modal.Body>
          <p>
            <b>{dialogStatus.msg}</b>
            <br />
            Want to proceed with changes?
          </p>
          <div>
            <Button
              className="button-custom"
              disabled={isLoading}
              onClick={() => {
                toggleStatus(selectedShow?.slug, dialogStatus?.type);
              }}
            >
              {isLoading ? "Loading..." : "Update"}
            </Button>
            <Button
              className="button-custom-cancel"
              onClick={() => {
                setDialogStatus({ open: false });
                setSelectShow({});
              }}
            >
              Cancel
            </Button>
          </div>
        </Modal.Body>
      </Modal>

      {/* CREATE FORM MODAL */}
      <Modal show={isDialogCreate} centered>
        <Modal.Header closeButton onHide={toggleCreate}>
          <div className="modal-video-title">Create Show</div>
        </Modal.Header>
        <Modal.Body>
          <CreateShow toggleCreate={toggleCreate} />
        </Modal.Body>
      </Modal>

      {/* Alert */}
      <AlertComponent
        message={errorMsg?.error}
        type={errorMsg?.type}
        setAlertNotification={() => setErrorMsg({ error: "", type: "failed" })}
      />
    </div>
  );
}
